@font-face {
    font-family: 'Kirlian-Bold';
     src: url('./../libs/Kirlian-Bold.woff' ) format('woff');
     font-display: swap;
}


@font-face {
    font-family: 'Euclid-Circular-Bold';
    src: url('./../libs/EuclidCircularA-Bold.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Euclid-Circular-Italic';
    src: url('./../libs/EuclidCircularA-Italic.woff') format('woff');
    font-display: swap;
}

.bold {
    font-family: 'Euclid-Circular-Bold';
    font-weight: bold;
    font-style: normal;
  }


.italic {
    font-family: 'Euclid-Circular-Italic';
    font-weight: normal;
    font-style: italic;
}